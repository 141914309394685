import request from '@/utils/requests'
import qs from 'qs'

// 
export const lnicioMore= data => {
  return request({
    url: '/topic/vod_list',
    method: 'post',
    data:qs.stringify(data)
  })
}