<template>
  <div class="more">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="onClickLeft"/>
<!--      <div style="border-bottom: 0.001rem solid #323745"></div>-->
    </van-sticky>

    <div class="content">
      <!-- 添加下拉加载 -->
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('finish')"
          :loading-text="$t('loading')"
          @load="onLoad"
      >
        <div class="contentCard" v-for="item in moreInfo" :key="item.id" @click="toPlays(item.id)">
          <div class="contentCareImg">
            <van-image
                width="3.1rem"
                radius="0.1rem"
                :src="item.vod_pic"
            />
            <div class="score">
              {{ item.vod_douban_score }}
            </div>
          </div>
          <div class="contentCardText">
            <div class="van-multi-ellipsis--l2">{{ item.vod_name }}</div>
            <div class="van-ellipsis" v-show="item.vod_director">{{ $t('director') }}: {{ item.vod_director }}</div>
            <div class="van-multi-ellipsis--l2" v-show="item.vod_actor">{{ $t('actor') }}: {{ item.vod_actor }}</div>
            <div>
              <div class="van-ellipsis">{{ item.vod_tag }}</div>
              <div class="year">{{ item.vod_year }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import {lnicioMore} from '@/api/lnicio/more.js'
import noneImg from '@/assets/猜你喜欢默认.png'

export default {
  name: '',
  components: {},
  data() {
    return {
      moreInfo: [], // 更多的列表信息
      title: null,
      loading: false,
      finished: false,
      pn: 1,
      topicId: 0,
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  created() {
    this.moreInfo = []
    const list = this.$route.query
    this.topicId = list['topic_id']
    this.pn = list['pn'] || 1
  },
  mounted() {
  },
  methods: {
    onLoad() {
      lnicioMore({"topic_id": this.topicId, "pn": this.pn}).then(res => {
        this.loading = false
        let vod_list = res.result.vod_list
        if (vod_list.length > 1) {
          for (let item of vod_list) {
            this.moreInfo.push(item)
          }
        } else {
          this.finished = true
        }

        this.title = res.result.name
      })
      this.pn++
    },
    onClickLeft() {
      this.$router.go(-1)
    },

    // 图片路径错误时
    getDefaultImage(event) {
      event.target.src = noneImg;
    },
    // 去播放页面
    toPlays(vod_id) {
      const query = {
        vod_id
      }
      this.$router.push({
        path: '/play',
        query
      })
    }
  }
}
</script>
<style lang="scss" scoped>

// 隐藏上面导航底部白线
.van-hairline--bottom:after {
  //border-bottom-width: 0px;
  border-bottom-color: var(--minor-color);
}

.content {
  width: 100vw;
  // margin-top: 10px;
  // padding:  0 5px;
}

.contentCard {
  font-family: PingFang SC;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: var(--title-color);
  display: flex;
  padding: 0.1rem 0.2rem;
}

.contentCareImg {
  position: relative;
}

.contentCardText {
  margin-left: 0.2rem;
  width: calc(100% - 3.3rem);
}

.contentCardText > div:nth-of-type(1) {
  font-size: 16px;
  line-height: 25px;
  opacity: 0.8;
  padding-top: 0.1rem;
}

.contentCardText > div:nth-of-type(2) {
  font-size: 12px;
  line-height: 17px;
  color: var(--minor-text-color);
  padding-top: 10px;
}

.contentCardText > div:nth-of-type(3) {
  font-size: 12px;
  line-height: 17px;
  color: var(--minor-text-color);
  padding-top: 10px;
}

.contentCardText > div:nth-of-type(4) {
  font-size: 12px;
  line-height: 17px;
  color: var(--minor-text-color);
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
}

.score {
  font-size: 11px;
  line-height: 16px;
  color: #ef233c;
  position: absolute;
  right: 0.2rem;
  bottom: 0.2rem;
  font-weight: bold;
}

.year {
  padding: 4px 5px;
  background-color: var(--minor-color);
  border-radius: 2px;
  margin-left: 0.2rem;
}

</style>
